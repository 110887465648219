import { useEffect } from "react"
import Hero from "../components/home/Hero"
import Sogehts from "../components/home/Sogehts"

function PreScreen() {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<>
			<Hero />
			<Sogehts isPromoSoon={true} />
		</>
	)
}
export default PreScreen
