import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import AccordionItem from "../components/elements/AccordionItem"
import { PACKAGES } from "../config"

const FAQ: React.FC = () => {
	const navigate = useNavigate()

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<>
			<div className="pt-10 sm:pt-40">
				<div className="container max-w-[800px] mx-auto px-4 ">
					<h2 className="h2 pt-12 text-center">FAQ</h2>

					<h2 className="h2 my-16 text-center ">1. Allgemeine Informationen </h2>

					<AccordionItem headline="Worum geht es bei der aktuellen Aktion von Werther’s Original Caramel Bites?" num="1.">
						<p className="text-black">
							Im Rahmen unserer Gratis-probieren-Aktion kann ein Werther’s Original Caramel Bites Aktionsprodukt gratis getestet werden. Nach erfolgreicher Online-Teilnahme wird
							dir der gezahlte Kaufpreis des Aktionsproduktes auf das von dir benannte Bankkonto zurücküberwiesen.
						</p>
					</AccordionItem>

					<AccordionItem headline="Wer darf an der Aktion teilnehmen?	" num="2.">
						<p className="text-black">
							Teilnahmeberechtigt sind natürliche Personen ab 18 Jahren mit einem Konto im SEPA Raum (ein Land innerhalb der Europäischen Union sowie Island, Lichtenstein,
							Norwegen, Schweiz, Andorra, Monaco, San Marino, Vatikanstadt, Vereinigtes Königreich, Gibraltar, Isle of Man, Guernsey und Jersey).
						</p>
					</AccordionItem>

					<AccordionItem headline="Welche Produkte nehmen an der Aktion teil?" num="3.">
						<p className="text-black">
							An der Aktion nehmen folgende Produkte teil: Werther’s Original Caramel Bites Crunchy 140g und Werther’s Original Caramel Bites Cookie 140g, die mit dem
							Aktionshinweis „Jetzt gratis probieren“ versehen sind.
							<br />
							<br />
							<br />
							{Object.keys(PACKAGES).map(item => {
								return (
									<div key={"package" + PACKAGES[item]} className=" inline-block px-6">
										<img src={process.env.PUBLIC_URL + "/images/packs/" + item + ".png"} alt={item} loading="lazy" className="w-auto  max-h-[230px] mx-auto pb-5" />
										<div className="relative pl-6"></div>
									</div>
								)
							})}
						</p>
					</AccordionItem>

					<AccordionItem headline="Wo finde ich die Aktionspackungen?" num="4.">
						<p className="text-black">
							Die Aktionspackungen sind sowohl in Deutschland als auch Österreich bei den teilnehmenden Händlern verfügbar. Wir können leider nicht sagen, ob und wann die
							Aktionspackungen in einem konkreten Einkaufsmarkt erhältlich sind. Wenn du bisher keine Aktionspackungen finden konntest, probiere es am besten in einem anderen Markt
							– oder schau einfach beim nächsten Einkauf nochmal beim Süßwaren-Regal vorbei.
						</p>
					</AccordionItem>

					<h2 className="h2 my-16 text-center ">2. Durchführung und Abwicklung</h2>

					<AccordionItem headline="Wie kann ich an der Aktion teilnehmen?" num="5.">
						<p className="text-black">
							1. Eine Aktionspackung Werther’s Original Caramel Bites kaufen. <br />
							<br />
							2. Fotos vom Kassenbon sowie der dazugehörigen Aktionspackung mit Aktionshinweis auf der Aktionsseite auf{" "}
							<a href="https://www.werthersoriginal-gratisprobieren.de" className="underline" target="_blank" rel="noreferrer">
								www.werthersoriginal-gratisprobieren.de
							</a>{" "}
							hochladen. <br />
							<br />
							3. Das Kontaktformular, Produktangaben und Bankinformationen ausfüllen. Das Mindestalter akzeptieren und auf „Jetzt teilnehmen“ klicken. <br />
							<br />
							4. Nach dem Absenden der Teilnahmedaten erhältst du per E-Mail eine Eingangsbestätigung. Storck prüft im Anschluss die Einhaltung der Teilnahmevoraussetzungen. Falls
							diese nicht vorliegen, erhältst du eine Benachrichtigung. Bei Vorliegen der Teilnahmebedingungen wird der Betrag für das Werther’s Original Caramel Bites
							Aktionsprodukt binnen 6 Wochen auf das von dir angegebene Konto erstattet.
						</p>
					</AccordionItem>

					<AccordionItem headline="In welchem Zeitraum kann an der Aktion teilgenommen werden?" num="6.">
						<p className="text-black">
							Die Aktion „Werther’s Original Caramel Bites gratis probieren“ findet bis zum 31.05.2024 statt. Fotos vom Kassenbon und der Aktionspackung können bis zum 31.05.2024
							um 23:59 Uhr ausschließlich online unter{" "}
							<a href="https://www.werthersoriginal-gratisprobieren.de" className="underline" target="_blank" rel="noreferrer">
								www.werthersoriginal-gratisprobieren.de
							</a>{" "}
							hochgeladen werden.
						</p>
					</AccordionItem>

					<AccordionItem headline="Wie lade ich die Fotos vom Kassenbon und der Aktionspackung richtig hoch?" num="7.">
						<p className="text-black">
							Es können beim Upload nur Fotos im Format JPG, PNG und PDF und einer maximalen Größe von 8 MB pro Foto hochgeladen werden. Auf dem Kassenbon müssen die folgenden
							Daten erkennbar sein: Kaufdatum, Kaufpreis des Aktionsproduktes, Bonsumme, Einkaufsstätte sowie das Aktionsprodukt. Auf dem Kassenbon abgebildete personenbezogene
							Daten (z. B. Namen von Beschäftigten) sind nicht abzulichten oder vorab zu schwärzen oder in sonstiger Weise unkenntlich zu machen. Unvollständige sowie verspätete
							Angaben können leider nicht berücksichtigt werden. Beim Foto der Aktionspackung muss der Aktionshinweis sichtbar sein.
						</p>
					</AccordionItem>

					<AccordionItem headline="Wo finde ich meine IBAN/BIC?" num="8.">
						<p className="text-black">
							Deine persönliche IBAN-Nummer und den BIC findest du auf der Vorder- und/oder Rückseite deiner Bankkarte sowie im Online-Banking. Die Zahlenfolge der IBAN für
							Deutschland beginnt stets mit DE und enthält 22 Zeichen. Die Zahlenfolge der IBAN für Österreich beginnt stets mit AT und enthält 20 Zeichen.
						</p>
					</AccordionItem>

					<AccordionItem headline="Ich habe versehentlich einen Fehler bei der Dateneingabe gemacht, was kann ich tun?" num="9.">
						<p className="text-black">
							Bitte wende dich umgehend an den Werther’s Original Caramel Bites Aktions-Kundenservice telefonisch unter 030 / 39 93 10 45 (erreichbar von Montag bis Freitag von
							08:30 - 17:30 Uhr) oder per E-Mail an{" "}
							<a className="underline" href="mailto:info@werthersoriginal-gratisprobieren.de">
								info@werthersoriginal-gratisprobieren.de
							</a>
							. Wenn die Rücküberweisung noch nicht getätigt wurde, können wir deine Daten korrigieren.
						</p>
					</AccordionItem>

					<AccordionItem headline="Warum wird mein Kassenbon nicht erkannt? " num="10.">
						<p className="text-black">
							Auf dem Kassenbon müssen die folgenden Daten erkennbar sein: Kaufdatum, Kaufpreis des Aktionsproduktes, Bonsumme, Einkaufsstätte sowie das Aktionsprodukt.
						</p>
					</AccordionItem>

					<AccordionItem headline="Werde ich informiert, wenn die Teilnahmebedingungen nicht erfüllt sind?" num="11.">
						<p className="text-black">
							Wir informieren dich, wenn die Teilnahmebedingungen nicht erfüllt sind. Du erhältst in diesem Fall von uns eine Benachrichtigung an deine angegebene E-Mail-Adresse.
						</p>
					</AccordionItem>

					<AccordionItem headline="Ist eine Mehrfach-Teilnahme möglich?" num="12.">
						<p className="text-black">
							Pro Bankkonto kann während des Aktionszeitraums ein Beutel Werther’s Original Caramel Bites gratis probiert werden. Jeder Kassenbon und jede Aktionspackung darf nur
							einmalig zur Teilnahme an der Aktion genutzt werden.
						</p>
					</AccordionItem>

					<AccordionItem headline="Ich habe den Kassenbon und/oder den Aktionsbeutel nicht mehr, was kann ich tun bzw. kann ich trotzdem teilnehmen?" num="13.">
						<p className="text-black">Eine Teilnahme ohne ein Foto des Kassenbons und/oder des Aktionsprodukts ist leider nicht möglich.</p>
					</AccordionItem>

					<h2 className="h2 my-16 text-center ">3. Nach der Teilnahme</h2>

					<AccordionItem headline="Wie kann ich sicher sein, dass meine Teilnahme an der Aktion erfolgreich war? " num="14.">
						<p className="text-black">
							Nach dem Absenden der Teilnahmedaten erhältst du per E-Mail eine Eingangsbestätigung. Storck prüft im Anschluss die Einhaltung der Teilnahmevoraussetzungen. Falls
							diese nicht vorliegen, erhältst du eine Benachrichtigung. Eine Bestätigung der Überweisung wird nicht versendet.
						</p>
					</AccordionItem>

					<AccordionItem headline="Wie lange dauert es, bis ich mein Geld zurückerhalte?" num="15.">
						<p className="text-black">
							Sofern du alle Teilnahmevoraussetzungen erfüllt hast, bekommst du den Kaufbetrag innerhalb eines Zeitraums von 6 Wochen auf das von dir angegebene Konto überwiesen.
						</p>
					</AccordionItem>

					<AccordionItem headline="Ist der Status der Rückerstattung einsehbar?	" num="16.">
						<p className="text-black">
							Leider ist der Status der Rückerstattung nicht online abrufbar. Um Auskunft über den Status der Rückerstattung zu erhalten, kannst du dich unter Angabe deines Namens
							per E-Mail an{" "}
							<a className="underline" href="mailto:info@werthersoriginal-gratisprobieren.de">
								info@werthersoriginal-gratisprobieren.de
							</a>{" "}
							oder telefonisch unter 030 / 39 93 10 45 an das Service-Team wenden.
						</p>
					</AccordionItem>

					<h2 className="h2 my-16 text-center ">4. Sonstiges</h2>

					<AccordionItem headline="Was passiert mit meinen Daten?" num="17.">
						<p className="text-black">
							Storck nutzt deine Daten im Rahmen der Aktion „Werther’s Original Caramel Bites gratis probieren“ ausschließlich zum Zwecke der Durchführung und Abwicklung der
							Aktion. Eine weitergehende Nutzung durch Storck oder Dritte findet nicht statt. Im Rahmen der Aktion arbeitet Storck mit seiner Tochtergesellschaft Storck Deutschland
							KG sowie dem sorgfältig ausgewählten Dienstleister direct services Gütersloh GmbH zusammen. Die für Storck tätigen Auftragsverarbeiter sind ordnungsgemäß auf die
							Einhaltung des Datenschutzes verpflichtet. Weitere Informationen zum Datenschutz findest du unter:{" "}
							<a
								className="underline"
								href="https://www.werthers-original.de/de/datenschutz"
								onClick={() => {
									try {
										// eslint-disable-next-line
										let dataLayer = window["dataLayer"] || []
										dataLayer.push({ event: "faq", link: "datenschutz" })
									} catch (e) {}
								}}
							>
								https://www.werthers-original.de/de/datenschutz
							</a>
						</p>
					</AccordionItem>

					<div className="text-brown pt-8 text-center">
						<p className="mb-6 text-black">Du hast keine Antwort auf deine Frage erhalten?</p>
						<p className="text-black">
							Das Service-Team ist von Montag bis Freitag von 08:30 - 17:30 Uhr telefonisch erreichbar unter 030 / 39 93 10 45 oder per E-Mail unter{" "}
							<a className="underline" href="mailto:info@werthersoriginal-gratisprobieren.de">
								info@werthersoriginal-gratisprobieren.de
							</a>
							.
						</p>
					</div>
				</div>
			</div>
			<div className="text-center pt-5 py-10">
				<button
					onClick={() => {
						navigate(-1)
						try {
							// eslint-disable-next-line
							let dataLayer = window["dataLayer"] || []
							dataLayer.push({ event: "faq", link: "zurück" })
						} catch (e) {}
					}}
					className="mt-8 btn btn-default"
				>
					Zurück
				</button>
			</div>
		</>
	)
}
export default FAQ
