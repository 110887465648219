export enum ERRORTYPES {
	FORMVALIDATION = "Bitte fülle alle Felder korrekt aus.",
	RECEIPT_UPLOAD_ERROR = "Leider stimmt etwas mit Deinem Kassenbon nicht. Wir können keinen Kauf eines teilnehmenden Produktes erkennen. ",
	FILESIZE = "Dein ausgewählter Kassenbon entspricht nicht den Vorgaben. JPG, PNG oder PDF / max. 8 MB",
	DOUBBLED = "Du hast Deinen Kassenbon bereits verwendet.",
	MAXTRIES = "Du hast heute bereits zu häufig an unserem Gewinnspiel teilgenommen. Bitte probiere es morgen noch einmal.",
	SERVER = "Bei der Übertragung Deiner Daten ist ein Fehler aufgetreten. Bitte versuche es erneut. Sollten die Probleme weiterhin bestehen, kannst Du Dich an unser Service-Team wenden. Du erreichst uns per E-Mail unter info@werthers-gratisprobieren.de oder telefonisch montags bis freitags von 08:30 - 17:30 Uhr unter 030 - 47 06 00 60 . Bitte entschuldige die Unannehmlichkeiten.",
	UPLOADSMISSING = "Bitte lade einen Kassenbon und ein Produktfoto hoch. ",
	UPLOADSMISSINGRECEIPT = "Bitte lade deinen Kassenbon hoch.",
	UPLOADSMISSINGPRODUCT = "Bitte lade dein Produktfoto hoch.",
}

export const PACKAGES = {
	a: "Caramel Bites Cookie",
	b: "Caramel Bites Crunchy",
}

export const MAX_PRODUCT_PRICE = 5.0

export enum COUNTRY {
	DE = "de",
	AT = "at",
}
