import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"

const Imprint: React.FC = () => {
	const navigate = useNavigate()

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<div className="pt-10 sm:pt-40">
			<div className="container max-w-[400px] mx-auto px-4 text-center">
				<h2 className="h2"> Impressum </h2>
				<dl>
					<h2 className="mt-8 text-[20px] font-bold font-Atma text-brown leading-tight mb-2">AUGUST STORCK KG</h2>{" "}
					<dd>
						{" "}
						Waldstraße 27
						<br /> 13403 Berlin
						<br /> Deutschland
						<br /> <br /> Telefon: +49 30 41773-03
						<br /> Fax: +49 30 41773 12-7000
						<br /> E-Mail:{" "}
						<a href="mailto:info@de.storck.com" className="underline text-brown">
							info@de.storck.com
						</a>{" "}
					</dd>{" "}
					<h2 className="mt-8 text-[20px] font-bold font-Atma text-brown leading-tight mb-2">Registerangaben:</h2>{" "}
					<dd>
						{" "}
						Registergericht: Amtsgericht Charlottenburg
						<br /> Handelsregisternummer: HRA 22321
						<br /> U-St-Id-Nr.: DE 136654941{" "}
					</dd>{" "}
					<h2 className="mt-8 text-[20px] font-bold font-Atma text-brown leading-tight mb-2">Vertretungsberechtigt nach TMG:</h2>{" "}
					<dd>
						{" "}
						Axel Oberwelland
						<br /> August Storck Verwaltungsgesellschaft mbH
						<br /> Geschäftsführer:
						<br /> Sascha Gervers
						<br /> Jan Hendricks
						<br /> Achim Westerhoff
						<br /> Michael Zink
						<br />{" "}
					</dd>{" "}
					<dd>
						{" "}
						Registergericht: Amtsgericht Charlottenburg
						<br /> Handelsregisternummer: HRB 30978
						<br />{" "}
					</dd>{" "}
					<h2 className="mt-8 text-[20px] font-bold font-Atma text-brown leading-tight mb-2">Copyright:</h2>{" "}
					<dd>
						{" "}
						© Copyright 2019, AUGUST STORCK KG, Deutschland
						<br /> Alle Rechte vorbehalten.
						<br /> Die auf unserer Webseite abrufbaren Inhalte unterliegen dem Schutz des Urheberrechts und anderer Schutzgesetze. Dies gilt insbesondere für Texte, Bilder,
						Grafiken, Audio- und Videodateien sowie deren Anordnung auf der Seite. Soweit nicht anders angegeben, sind alle Markenzeichen auf Storck Websites markenrechtlich
						geschützt.
						<br />
						<br /> Jegliche Nutzung bedarf der vorherigen schriftlichen Zustimmung durch die AUGUST STORCK KG.{" "}
					</dd>{" "}
					<h2 className="mt-8 text-[20px] font-bold font-Atma text-brown leading-tight mb-2">Website Realisierung:</h2> <dd>direct services Gütersloh GmbH</dd>{" "}
				</dl>
			</div>
			<div className="text-center pt-5 py-10">
				<button onClick={() => navigate(-1)} className="btn btn-default mt-8">
					Zurück
				</button>
			</div>
		</div>
	)
}
export default Imprint
