import React from "react"
import Hero from "../components/home/Hero"
import Receipt from "../components/receipt/Receipt"
import Sogehts from "../components/home/Sogehts"
import Form from "../components/Forms/Form"

const Home: React.FC = () => {
	return (
		<>
			<Hero />

			<div className="relative h-0 text-center z-20">
				<button
					className="btn btn-default -translate-y-1/2 "
					onClick={() => {
						const doc: any = document
						const { top } = doc.querySelector(".js-mitmachen").getBoundingClientRect()

						window.scrollTo({
							top: top + window.scrollY,
							left: 0,
							behavior: "smooth",
						})

						try {
							// eslint-disable-next-line
							let dataLayer = window["dataLayer"] || []
							dataLayer.push({ event: "header", cta: "jetzt gratis probieren" })
							console.log(dataLayer)
						} catch (e) {}
					}}
				>
					JETZT mitmachen
				</button>
			</div>

			<Sogehts />
			<Receipt />
			<Form />
		</>
	)
}
export default Home
