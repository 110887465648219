import { Link } from "react-router-dom"

import "./slickslider.css"
import Slider from "react-slick"
const Sogehts = ({ isPromoSoon = false }) => {
	let settings = {
		dots: false,
		arrows: true,
		infinite: false,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	}

	return (
		<>
			{isPromoSoon && (
				<div className="text-center font-Poppins text-[37px] sm:text-[51px] leading-[43px] sm:leading-[58px] text-red font-extrabold px-4 pt-6 sm:pt-12 -mb-12">
					AB DEM 02.01.2024 GEHT'S LOS!
				</div>
			)}
			<div className="text-center relative z-0  pt-16 md:pt-24 js-sogehts">
				<h2 className="h2 ">So geht's</h2>
			</div>
			<div className="text-center max-w-[1000px] mx-auto">
				<Slider {...settings}>
					<div>
						<img src={process.env.PUBLIC_URL + "/images/step1.svg"} className="w-full h-auto max-w-[185px] mx-auto" alt="" />
						<div className="sogehts-text">
							<div className="sogehts-text-inner">Einen Aktionsbeutel Werther‘s Original Caramel Bites kaufen</div>
						</div>
					</div>
					<div>
						<img src={process.env.PUBLIC_URL + "/images/step2.svg"} className="w-full h-auto max-w-[185px] mx-auto" alt="" />
						<div className="sogehts-text">
							<div className="sogehts-text-inner">Fotos vom Kassenbon und Aktionsbeutel auf www.werthersoriginal-gratisprobieren.de hochladen</div>
						</div>
					</div>
					<div>
						<img src={process.env.PUBLIC_URL + "/images/step3.svg"} className="w-full h-auto max-w-[185px]  mx-auto" alt="" />
						<div className="sogehts-text">
							<div className="sogehts-text-inner">Geld auf‘s Konto zurückerhalten</div>
						</div>
					</div>
				</Slider>
			</div>

			<div className="text-ocker text-[22px] font-Poppins text-center font-bold mt-9">Teilnahme bis 31.05.2024 möglich.</div>

			<div className=" text-center px-4 mx-auto text-brown py-4 text-[14px] sm:text-[15px] leading-normal max-w-4xl">
				Teilnahme ab 18 Jahren. <br />
				Pro Bankkonto kann ein Werther‘s Original Caramel Bites Aktionsprodukt einmal gratis probiert werden. <br />
				Weitere Informationen in den{" "}
				<Link
					to="/teilnahmebedingungen"
					className="underline"
					onClick={() => {
						try {
							// eslint-disable-next-line
							let dataLayer = window["dataLayer"] || []
							dataLayer.push({ event: "sogehts", link: "teilnahmebedingungen" })
						} catch (e) {}
					}}
				>
					Teilnahmebedingungen
				</Link>
				.
			</div>

			{isPromoSoon && (
				<div className="pt-16 pb-20 text-center ">
					<a href={"https://www.werthers-original.de/"} className="btn btn-default  " target="_blank">
						Zu Werther‘s original
					</a>
				</div>
			)}
		</>
	)
}

export default Sogehts
