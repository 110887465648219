import { useAutoAnimate } from "@formkit/auto-animate/react"

interface InputProps {
	props?: any
	className?: string | null
	label?: string | null
	errMsg?: string
	hasError?: boolean
}

const ElemSelect: React.FC<InputProps> = ({ className, props, label, errMsg, hasError, children }) => {
	const [animationRef] = useAutoAnimate<HTMLDivElement>()
	return (
		<label className={"relative block " + (className || "")}>
			<select
				className={
					" font-semibold  pt-4 w-full peer rounded-[23px] px-4 text-base  text-brown h-[47px] leading-[40px] placeholder-transparent outline-0 bg-white  appearance-none bg-right bg-no-repeat bg-[url('/src/bgimages/icon-dropdown.png')] bg-[length:auto_100%]" +
					(hasError ? "  js-formerror " : "")
				}
				style={{ boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset" }}
				{...props}
			>
				{children}
			</select>
			<span
				className={
					(!!props.value ? "-translate-y-2" : "") +
					" -translate-y-2 transition absolute left-0 top-0 font-Poppins text-base pointer-events-none px-4 h-[47px] leading-[47px]" +
					(hasError ? "  text-red " : " text-brown ")
				}
			>
				{label}
			</span>
			<div ref={animationRef}>{hasError && <div className="formErrorMsg ">{errMsg}</div>}</div>
		</label>
	)
}

export default ElemSelect
