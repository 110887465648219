import { useContext, useState } from "react"
import { Context } from "../../store/store"
import Popup from "../elements/Popup"
import ReceiptUpload from "./ReceiptUpload"
import { useAutoAnimate } from "@formkit/auto-animate/react"
import ProductFotoUpload from "./ProductFotoUpload"

const Receipt = () => {
	const { dispatch } = useContext(Context)
	const [showSample, setShowSample] = useState<boolean>(false)
	const [animationRef] = useAutoAnimate<HTMLDivElement>()

	return (
		<>
			<section className="sm:px-4 pt-6 js-mitmachen relative">
				<div className="container text-center">
					<h2 className="px-4 h2 pb-10  max-w-[900px] mx-auto">Jetzt mitmachen und Geld zurückerhalten!</h2>
					<div className="relative z-10 bg-red px-[4%] pb-8 md:pb-12 ">
						<h3 className="px-4 text-[21px] sm:text-[23px] sm:leading-[33px] max-w-[1000px] mx-auto py-11 text-yellow font-normal">
							Lade jetzt ein Foto des Kassenbons sowie der dazugehörigen Aktionspackung hoch und klicke auf „Jetzt teilnehmen".
						</h3>

						<div className="md:px-8 ">
							<div className="grid md:grid-cols-2 gap-4 sm:gap-x-16 md:gap-x-16">
								<div
									className="p-1 sm:px-8 bg-white rounded-xl border-dashed border-[1px] order-1  sm:order-1"
									style={{
										boxShadow: "0px 4px 25px 0px rgba(100, 47, 13, 0.20)",
									}}
								>
									<div className=" px-4 pb-4">
										<img src={process.env.PUBLIC_URL + "/images/icon-receipt.png"} alt="" className="inline-block w-[130px] pb-[1%] h-auto " />
										<h5 className="h5 pb-4 ">Foto vom Kassenbon hochladen</h5>
										<ReceiptUpload dispatch={dispatch} />
									</div>
								</div>
								<div
									className="p-1 sm:px-8 bg-white rounded-xl border-dashed border-[1px]  order-3 sm:order-2"
									style={{
										boxShadow: "0px 4px 25px 0px rgba(100, 47, 13, 0.20)",
									}}
								>
									<div className="px-4 pb-4">
										<img src={process.env.PUBLIC_URL + "/images/icon-product.png"} width="410" height="478" alt="" className="inline-block w-[145px] h-auto " />
										<h5 className="h5 pb-4">Foto vom Aktionsprodukt hochladen</h5>

										<ProductFotoUpload dispatch={dispatch} />
									</div>
								</div>

								<div
									className="underline text-white text-base text-center sm:text-left sm:pt-5  order-2 sm:order-3"
									onClick={() => {
										setShowSample(true)
										try {
											// eslint-disable-next-line
											let dataLayer = window["dataLayer"] || []
											dataLayer.push({ event: "receipt", "receipt-upload": "show-sample-receipt" })
										} catch (e) {}
									}}
								>
									Beispiel ansehen
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<div ref={animationRef}>
				{showSample && (
					<Popup close={() => setShowSample(false)}>
						<h5 className="h5 pb-6">Beispiel-Kassenbon</h5>
						<img src={process.env.PUBLIC_URL + "/images/bon-sample.png"} width="724" height="1462" alt="" className="mx-auto w-[200px] lg:w-[270px] h-auto " />
					</Popup>
				)}
			</div>
		</>
	)
}

export default Receipt
