import { useAutoAnimate } from "@formkit/auto-animate/react"

interface CheckboxProps {
	props?: any
	className?: string | null
	label?: any
	errMsg?: string
	hasError?: boolean
}

const ElemCheckbox: React.FC<CheckboxProps> = ({ className, props, label, errMsg, hasError }) => {
	const [animationRef] = useAutoAnimate<HTMLDivElement>()
	return (
		<label className={"relative block cursor-pointer " + (className || "")}>
			<input className={"w-1 h-1 hidden absolute peer "} {...props} />
			<img src={process.env.PUBLIC_URL + "/images/icon-checkbox.svg"} alt={"not selected"} loading="lazy" className="peer-checked:hidden block absolute w-[20px] left-0 top-1" />
			<img
				src={process.env.PUBLIC_URL + "/images/icon-checkbox-checked.svg"}
				alt={"selected"}
				loading="lazy"
				className="peer-checked:block hidden absolute w-[20px]  left-0 top-1"
			/>
			<div className="pl-8 text-lg sm:text-xl leading-xl font-Poppins text-brown">{label}</div>
			<div ref={animationRef}>{hasError && <div className="formErrorMsg  pl-8 ">{errMsg}</div>}</div>
		</label>
	)
}

export default ElemCheckbox

// https://play.tailwindcss.com/tKldzVn3U1
