const Hero = () => {
	return (
		<div className="relative">
			<img src={process.env.PUBLIC_URL + "/images/hero.png"} className="hidden sm:block w-full " alt="" />
			<img src={process.env.PUBLIC_URL + "/images/hero-mobile.png"} className="block sm:hidden w-full " alt="" />
			<a
				href="https://www.werthersoriginal-gratisprobieren.de/"
				className=" absolute left-[7%] top-[4%] w-[40%] h-[13%] sm:left-[7%] sm:top-[7%] sm:w-[20%] sm:h-[20%] cursor-pointer"
				target="_blank"
				rel="noreferrer"
			>
				{" "}
			</a>
		</div>
	)
}

export default Hero
