const domains = {
	"www.werthersoriginal-gratisprobieren.de": {
		apibase: "/",
		recaptchakey: "6LfFkoMfAAAAADHtHOaBDf3UJEdsRL8gGutdy_Uk",
		activatePromoSoon: true,
	},

	"prod.fe.werthers-original-caramel-bites.campaign-loyalty.com": {
		apibase: "/",
		recaptchakey: "6LfFkoMfAAAAADHtHOaBDf3UJEdsRL8gGutdy_Uk",
		activatePromoSoon: true,
	},
}
export const getEnvConfig = () => {
	return domains[window.location.host] || { apibase: "/inte/", recaptchakey: "6LfFkoMfAAAAADHtHOaBDf3UJEdsRL8gGutdy_Uk", activatePromoSoon: false }
}

// TODO: Recaptacha raus? Entscheidung steht noch aus
