import { useState } from "react"
import { useAutoAnimate } from "@formkit/auto-animate/react"
import { Link } from "react-router-dom"
import MobileMenu from "./MobileMenu"

const Header = ({ isPromoOver = false }) => {
	const [showMenu, setShowMenu] = useState(false)
	const [animationRef] = useAutoAnimate<HTMLDivElement>()

	function scrollToAnchor(anchor) {
		setTimeout(() => {
			try {
				const doc: any = document
				const { top } = doc.querySelector(".js-" + anchor).getBoundingClientRect()

				window.scrollTo({
					top: top + window.scrollY,
					left: 0,
					behavior: "smooth",
				})

				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({ event: "header", link: anchor })
				} catch (e) {}
			} catch (error) {}
		}, 300)
	}

	return (
		<>
			<div ref={animationRef}>{showMenu && <MobileMenu setShowMenu={setShowMenu} isPromoOver={isPromoOver} scrollToAnchor={scrollToAnchor} />}</div>

			<header className="h-0 z-20 relative p-0 pl-[20%]  grid grid-cols-2 md:grid-cols-[266px_1fr] gap-2 items-center">
				<div className=" ">
					<a
						href="https://www.werthersoriginal-gratisprobieren.de/"
						className=" absolute left-[7%] top-[8%] w-[40%] h-[13%] sm:left-[7%] sm:top-[7%] sm:w-[20%] sm:h-[20%] cursor-pointer"
						target="_blank"
						rel="noreferrer"
					>
						<img src={process.env.PUBLIC_URL + "/images/logo-werthers.png"} className="block lg:-ml-[6px] mt-[27px] lg:mt-[46px] w-full " alt="" />
					</a>
				</div>
				<ul className="hidden xl:flex flex-wrap  text-center justify-around pt-[6%]">
					{!isPromoOver && (
						<>
							<li>
								<Link to="/" onClick={() => scrollToAnchor("sogehts")} className="header-item">
									So geht's
								</Link>
							</li>
							<li>
								<Link to="/" onClick={() => scrollToAnchor("mitmachen")} className="header-item">
									jetzt mitmachen
								</Link>
							</li>
						</>
					)}
					<li>
						<Link
							to={process.env.PUBLIC_URL + "/teilnahmebedingungen"}
							className="header-item"
							onClick={() => {
								try {
									// eslint-disable-next-line
									let dataLayer = window["dataLayer"] || []
									dataLayer.push({ event: "header", link: "teilnahmebedingungen" })
								} catch (e) {}
							}}
						>
							teilnahmebedingungen
						</Link>
					</li>
					<li>
						<Link
							to={process.env.PUBLIC_URL + "/faq"}
							className="header-item"
							onClick={() => {
								try {
									// eslint-disable-next-line
									let dataLayer = window["dataLayer"] || []
									dataLayer.push({ event: "header", link: "faq" })
								} catch (e) {}
							}}
						>
							FAQ
						</Link>
					</li>
					<li>
						<Link
							to={process.env.PUBLIC_URL + "/kontakt"}
							className="header-item"
							onClick={() => {
								try {
									// eslint-disable-next-line
									let dataLayer = window["dataLayer"] || []
									dataLayer.push({ event: "header", link: "kontakt" })
								} catch (e) {}
							}}
						>
							Kontakt
						</Link>
					</li>
				</ul>
				<div className="block xl:hidden text-right  pr-6">
					<img
						src={process.env.PUBLIC_URL + "/images/icon-menu.png"}
						alt="Menu"
						loading="lazy"
						width="26"
						className=" w-[26px] float-right mt-[27%] sm:mt-[10%] cursor-pointer"
						onClick={() => setShowMenu(true)}
					/>
				</div>
			</header>
		</>
	)
}

export default Header
