import React, { createContext, useReducer } from "react"
import { PACKAGES } from "../config"
import Reducer from "./reducer"

declare var navigator

export const helper_getBrowserLocale = () => {
	// get lang from browser
	const userLang = navigator.language || navigator.userLanguage
	if (userLang === "de-AT") {
		return "at"
	}
	return "de"
}

const initialState = {
	salutation: "4", // 4 = none
	firstname: "",
	surname: "",
	email: "",

	purchaseAmount: "",
	// purchaseTotal: "",
	purchaseDate: "",
	merchant: "",

	bankName: "",
	iban: "",
	bic: "",

	age: "",

	captcha: "",
	captchaIdent: "",
	sessionID: null,

	country: helper_getBrowserLocale(),
	package: Object.keys(PACKAGES)[0],

	productImg: null,
	receiptReference: null,
	receiptFileName: null,

	question1: null,
	question2: null,
}

const Store = ({ children }) => {
	const [globalState, dispatch] = useReducer(Reducer, initialState)
	return <Context.Provider value={{ globalState, dispatch }}>{children}</Context.Provider>
}
export const Context = createContext<{
	globalState
	dispatch: React.Dispatch<any>
}>({
	globalState: initialState,
	dispatch: () => {},
})
export default Store
