interface RadioProps {
	props?: any
	className?: string | null
	label?: any
}

const RadioSurvey: React.FC<RadioProps> = ({ className, props, label }) => {
	return (
		<label className={"relative block cursor-pointer mb-4" + (className || "")}>
			<input className={"w-2 h-2 hidden absolute"} {...props} type="radio" value={props.value} />
			<div className="text-right text-[18px]  text-black pr-10 ">{label}</div>
			{props.checked ? (
				<img
					src={process.env.PUBLIC_URL + "/images/icon-checkbox-checked.svg"}
					alt={"selected"}
					loading="lazy"
					className="peer-checked:block -top-[2px] absolute w-[30px] right-0"
				/>
			) : (
				<img
					src={process.env.PUBLIC_URL + "/images/icon-checkbox.svg"}
					alt={"not selected"}
					loading="lazy"
					className="peer-checked:hidden block top-[2px] absolute w-[28px] 28px] right-0"
				/>
			)}
		</label>
	)
}

export default RadioSurvey
