import { useAutoAnimate } from "@formkit/auto-animate/react"
import { useContext, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { ERRORTYPES, MAX_PRODUCT_PRICE } from "../../config"
import { Context } from "../../store/store"
import Alert from "../elements/Alert"
import Spinner from "../elements/Spinner"
import BankAccount from "./BankAccount"
import Legal from "./Legal"
import { placeOrder } from "./OrderFct"
import Person from "./Person"
import Product from "./Product"

import SimpleCaptcha from "./SimpleCaptcha"
import Survey from "./Survey"
import PreFillForm from "./PrefillForm"

const Form = () => {
	const { globalState, dispatch } = useContext(Context)
	const [validation, setValidation] = useState({})
	const navigate = useNavigate()
	const [formError, setFormError] = useState<ERRORTYPES | null>(null)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [animationRef] = useAutoAnimate<HTMLDivElement>()

	function formElementChanged(e, key) {
		// special handling for checkboxes
		if (e.target.type === "checkbox") {
			e.target.value = e.target.checked
		}

		dispatch({
			type: "SET_ORDER_ENTRY",
			key: key,
			value: e.target.value
				.replace(/[^a-zA-ZÀ-ÖØ-öø-ÿ0-9 "#&()*+,-.\\/:?@_u|~áçíóôąćČčđėěğīİıĶŁłńŕśŞşŠšūźżŽž̈БКМСавгжиклнорстьіẞ–—‘’']/giu, "")
				.replace("https://www", "")
				.replace("mailto:", ""),
		}) // strip HTML tags

		let isFieldInvalid = false

		const doc: any = document
		const elem = doc.querySelector("[name='" + key + "']:valid")
		if (elem === null) {
			isFieldInvalid = true
		}

		// check for price maximum
		if (key === "purchaseAmount") {
			const price = parseFloat(e.target.value.replace(",", "."))
			if (price > MAX_PRODUCT_PRICE) {
				isFieldInvalid = true
			}
		}

		// check validation
		setValidation({ ...validation, ["invalid_" + key]: isFieldInvalid })
	}

	function submitOrder() {
		// create order part
		const orderSuccessCallback = extId => {
			setIsLoading(false)
			if (extId) {
				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({ event: "participation", participation: true })
				} catch (e) {}

				navigate("/bestaetigung")
			} else {
				setFormError(ERRORTYPES.SERVER)
				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({ event: "participation", participation: false, "field-error": "server" })
				} catch (e) {}
			}
		}
		const orderErrCallback = (tooManyAttemps: false, invalidCaptcha: false) => {
			setIsLoading(false)

			// trigger reset Captcha
			const doc: any = window.document
			doc.querySelector(".js-reloadCaptcha").click()

			//TODO: Handling needs to be switched to error codes (BE part still open)
			if (tooManyAttemps) {
				setFormError(ERRORTYPES.MAXTRIES)
			} else if (invalidCaptcha) {
				setValidation({ ...validation, invalid_captcha: true })
				setFormError(null)
			} else {
				setFormError(ERRORTYPES.SERVER)
			}
			try {
				// eslint-disable-next-line
				let dataLayer = window["dataLayer"] || []
				dataLayer.push({ event: "form-submit", "form-name": "participate", "form-submit": true, package: globalState.package })
			} catch (e) {}
		}

		if (allInputFieldsValid()) {
			if (isReceiptAndFotoGiven()) {
				setIsLoading(true)
				setFormError(null)
				placeOrder(globalState, setFormValidation, orderSuccessCallback, orderErrCallback)
			} else {
				if (globalState.receiptReference === null) {
					setFormError(ERRORTYPES.UPLOADSMISSINGRECEIPT)
				} else if (globalState.productImg === null) {
					setFormError(ERRORTYPES.UPLOADSMISSINGPRODUCT)
				} else {
					setFormError(ERRORTYPES.UPLOADSMISSING)
				}
			}
		} else {
			setFormError(ERRORTYPES.FORMVALIDATION)
			scrollToError()
		}
	}

	function isReceiptAndFotoGiven() {
		if (globalState.receiptReference && globalState.productImg) {
			return true
		}
		return false
	}

	function isBicRequired() {
		return globalState.iban && globalState.iban.length > 2 && globalState.iban.substring(0, 2).toUpperCase() !== "DE"
	}

	function allInputFieldsValid() {
		// input names has to fit to variable classNames
		let isAllValid = true
		let invalidFields = {}

		// Special handing for bic. Bic is only for non DE IBANs required
		let fieldsToCheck: any = []
		if (isBicRequired()) {
			fieldsToCheck = Object.keys(globalState).filter(
				e =>
					e !== "package" &&
					e !== "sessionID" &&
					e !== "question1" &&
					e !== "question2" &&
					e !== "captchaIdent" &&
					e !== "productImg" &&
					e !== "receiptReference" &&
					e !== "receiptFileName"
			)
		} else {
			fieldsToCheck = Object.keys(globalState).filter(
				e =>
					e !== "package" &&
					e !== "sessionID" &&
					e !== "question1" &&
					e !== "question2" &&
					e !== "captchaIdent" &&
					e !== "productImg" &&
					e !== "receiptReference" &&
					e !== "receiptFileName" &&
					e !== "bic"
			)
		}

		for (let index = 0; index < fieldsToCheck.length; index++) {
			const key = fieldsToCheck[index]
			const doc: any = document
			const elem = doc.querySelector("[name='" + key + "']:valid")

			if (elem === null) {
				isAllValid = false
				invalidFields["invalid_" + key] = true
				console.log("invalid_" + key)

				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({ event: "participation", participation: false, "field-error": key })
				} catch (e) {}
			}
		}

		if (Object.keys(invalidFields).length) {
			setValidation({ ...validation, ...invalidFields })
		}

		return isAllValid
	}

	function setFormValidation(data: {}) {
		setValidation({
			...validation,
			...data,
		})
		scrollToError()
	}

	function scrollToError() {
		setTimeout(() => {
			try {
				const doc: any = document
				const { top } = doc.querySelector(".js-formerror").getBoundingClientRect()

				window.scrollTo({
					top: top + window.scrollY - 120,
					left: 0,
					behavior: "smooth",
				})
			} catch (error) {}
		}, 500)
	}

	return (
		<form
			onSubmit={e => {
				e.preventDefault()
				submitOrder()
			}}
			noValidate={true}
			className="px-4 "
		>
			<PreFillForm {...{ formElementChanged }} />
			<Person {...{ validation, formElementChanged }} />
			<Product {...{ validation, formElementChanged }} />
			<BankAccount {...{ validation, formElementChanged }} />
			<Survey {...{ validation, formElementChanged }} />
			<Legal {...{ validation, formElementChanged }} />
			<SimpleCaptcha {...{ validation, formElementChanged }} />
			<div className="font-bold text-left sm:text-center text-brown text-base sm:text-lg">
				Hier findest du die{" "}
				<Link
					to="/teilnahmebedingungen"
					className="underline font-bold"
					onClick={() => {
						try {
							// eslint-disable-next-line
							let dataLayer = window["dataLayer"] || []
							dataLayer.push({ event: "form", link: "teilnahmebedingungen" })
						} catch (e) {}
					}}
				>
					Teilnahmebedingungen
				</Link>{" "}
				und die Informationen zum{" "}
				<Link
					to="/datenschutz"
					className="underline font-bold"
					onClick={() => {
						try {
							// eslint-disable-next-line
							let dataLayer = window["dataLayer"] || []
							dataLayer.push({ event: "form", link: "datenschutz" })
						} catch (e) {}
					}}
				>
					Datenschutz
				</Link>
				.
			</div>
			{/* {process.env.NODE_ENV === "development" && <>{JSON.stringify(globalState).replaceAll(",", ", ")}</>} */}
			<div className="pt-4 sm:pt-16 pb-[132px] text-center " ref={animationRef}>
				{formError && <Alert className={"my-4"}>{formError}</Alert>}
				<button type="submit" className="btn btn-default" disabled={isLoading ? true : undefined}>
					{isLoading && <Spinner />}
					jetzt teilnehmen
				</button>
			</div>
		</form>
	)
}

export default Form
