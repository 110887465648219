import { BrowserRouter, Route, Routes } from "react-router-dom"
import Footer from "./components/StorckFooter"
import Header from "./components/WerthersHeader"
import Contact from "./nav/Contact"
import FAQ from "./nav/FAQ"
import Home from "./nav/Home"
import Imprint from "./nav/Imprint"
import Privacy from "./nav/Privacy"
import Terms from "./nav/Terms"
import Error from "./nav/Error"
import Store from "./store/store"
import Confirm from "./nav/Confirm"
import Promoover from "./nav/PromoOver"
import PreScreen from "./nav/PreScreen"
import { getEnvConfig } from "./env"

const App = () => {
	const debugParam = window.location.search
	let showMainPage = false

	if (debugParam === "?development") {
		showMainPage = true
	}
	//  Tue Jan 02 2024 08:00:00 UTC+0100 (Mitteleuropäische Normalzeit)  https://time.is/de/Unix_time_converter
	const isPromoSoonTime = parseInt(Math.floor(Date.now() / 1000).toString()) > 1717192800

	const env = getEnvConfig()

	return (
		<BrowserRouter basename={`${process.env.PUBLIC_URL}`}>
			<Header isPromoOver={isPromoSoonTime} />
			<Store>
				<main className="bg-yellow">
					<Routes>
						{/* <Route path="/" element={<PreScreen />} /> */}

						{isPromoSoonTime ? (
							<>
								<Route path="/" element={<Promoover />} />
							</>
						) : (
							<>
								<Route path="/" element={<Home />} />
								<Route path="/promoover" element={<Promoover />} />
								<Route path="/promosoon" element={<PreScreen />} />
							</>
						)}
						<Route path="/faq" element={<FAQ />} />
						<Route path="/datenschutz" element={<Privacy />} />
						<Route path="/teilnahmebedingungen" element={<Terms />} />
						<Route path="/impressum" element={<Imprint />} />
						<Route path="/kontakt" element={<Contact />} />
						<Route path="/:err" element={<Error />} />
					</Routes>
				</main>
			</Store>
			<Footer />
		</BrowserRouter>
	)
}

export default App
