function Footer() {
	type TFooterLink = {
		text: string
		link: string
	}

	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "footer", link: label })
		} catch (e) {}
	}

	const footerLinks: TFooterLink[] = [
		// {
		// 	text: "FAQ",
		// 	link: process.env.PUBLIC_URL + "/faq",
		// },
		// {
		// 	text: "Kontakt",
		// 	link: process.env.PUBLIC_URL + "/kontakt",
		// },
		{
			text: "Datenschutz",
			link: process.env.PUBLIC_URL + "/datenschutz",
		},
		// {
		// 	text: "Teilnahmebedingungen",
		// 	link: process.env.PUBLIC_URL + "/teilnahmebedingungen",
		// },
		{
			text: "Impressum",
			link: process.env.PUBLIC_URL + "/impressum",
		},
	]

	return (
		<footer>
			<section className="bg-[#fdecd2]">
				<div className="p-8 text-center">
					<div className="">
						<a className="inline-block mx-auto" href="https://www.storck.com/de/" target="_blank" rel="noreferrer" onClick={() => track("storck-website")}>
							<img loading="lazy" src={process.env.PUBLIC_URL + "/images/footer/storck-2x.png"} alt="Storck" width="286" className="mx-auto md:mx-0 mb-4 md:mb-0" />
						</a>
					</div>
					{/*
						<div className="text-center ">
							 <a href="https://www.facebook.com/merci" target="_blank" rel="noreferrer" className="inline-block mx-2">
								<img loading="lazy" src={process.env.PUBLIC_URL + "/images/footer/footer-facebook.png"} alt="Facebook" width="26" className="facebook-logo" />
							</a>
							<a href="https://www.pinterest.de/sagsmitmerci/" target="_blank" rel="noreferrer" className="inline-block mx-2">
								<img loading="lazy" src={process.env.PUBLIC_URL + "/images/footer/footer-pinterest.png"} alt="Facebook" width="26" className="facebook-logo" />
							</a>
						</div>
						*/}

					<nav className="pt-4">
						<ul>
							{footerLinks.map((footerLink, i) => (
								<li key={i} className="inline-block p-3">
									<a href={footerLink.link} className="text-[14px] text-[#d48b28]" onClick={() => track(footerLink.text)}>
										{footerLink.text}
									</a>
								</li>
							))}
						</ul>
					</nav>
				</div>
			</section>

			<section className="px-4 py-12 bg-[#f7f7f7]">
				<img src={process.env.PUBLIC_URL + "/images/footer/headline-brandbar-2x.png"} alt="Schon probiert?" className="mx-auto mb-4" width="280" />

				<div className="flex justify-center flex-wrap text-center">
					<a href="https://www.merci.de/" target="_blank" rel="noreferrer" className="basis-1/2 md:basis-auto" onClick={() => track("toffifee")}>
						<img
							className="mx-auto grayscale hover:grayscale-0 opacity-50 hover:opacity-100"
							loading="lazy"
							src={process.env.PUBLIC_URL + "/images/footer/logo-merci.png"}
							alt="toffifee"
							width={"140"}
						/>
					</a>
					<a href="https://www.toffifee.de/de/start/" target="_blank" rel="noreferrer" className="basis-1/2 md:basis-auto" onClick={() => track("toffifee")}>
						<img
							className="mx-auto grayscale hover:grayscale-0 opacity-50 hover:opacity-100"
							loading="lazy"
							src={process.env.PUBLIC_URL + "/images/footer/logo-toffifee-hover-2x.9528d53d.png"}
							alt="toffifee"
							width={"140"}
						/>
					</a>
					<a href="https://www.nimm2.de/de/start" target="_blank" rel="noreferrer" className="basis-1/2 md:basis-auto" onClick={() => track("Nimm2")}>
						<img
							className="mx-auto grayscale hover:grayscale-0 opacity-50 hover:opacity-100"
							loading="lazy"
							src={process.env.PUBLIC_URL + "/images/footer/logo-nimm2-hover-2x.550deaff.png"}
							alt="Nimm2"
							width={"140"}
						/>
					</a>
					<a href="https://www.knoppers.de/" target="_blank" rel="noreferrer" className="basis-1/2 md:basis-auto" onClick={() => track("knoppers")}>
						<img
							className="mx-auto grayscale hover:grayscale-0 opacity-50 hover:opacity-100"
							loading="lazy"
							src={process.env.PUBLIC_URL + "/images/footer/logo-knoppers-hover-2x.e132d7ed.png"}
							alt="knoppers"
							width={"140"}
						/>
					</a>

					<a href="https://www.mamba.de/de/start" target="_blank" rel="noreferrer" className="basis-1/2 md:basis-auto" onClick={() => track("mamba")}>
						<img
							className="mx-auto grayscale hover:grayscale-0 opacity-50 hover:opacity-100"
							loading="lazy"
							src={process.env.PUBLIC_URL + "/images/footer/logo-mamba-hover-2x.8ca762ac.png"}
							alt="mamba"
							width={"140"}
						/>
					</a>
					<a href="https://www.dickmanns.de/" target="_blank" rel="noreferrer" className="basis-1/2 md:basis-auto" onClick={() => track("dickmanns")}>
						<img
							className="mx-auto grayscale hover:grayscale-0 opacity-50 hover:opacity-100"
							loading="lazy"
							src={process.env.PUBLIC_URL + "/images/footer/logo-dickmanns-hover-2x.e6df4c65.png"}
							alt="dickmanns"
							width={"140"}
						/>
					</a>
					<a href="https://www.riesen.de/" target="_blank" rel="noreferrer" className="basis-1/2 md:basis-auto" onClick={() => track("riesen")}>
						<img
							className="mx-auto grayscale hover:grayscale-0 opacity-50 hover:opacity-100"
							loading="lazy"
							src={process.env.PUBLIC_URL + "/images/footer/logo-riesen-hover-2x.c5f17ccc.png"}
							alt="riesen"
							width={"140"}
						/>
					</a>
				</div>
			</section>
		</footer>
	)
}

export default Footer
