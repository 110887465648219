import { useAutoAnimate } from "@formkit/auto-animate/react"
import axios from "axios"
import { useState } from "react"
import Dropzone from "react-dropzone"
import { ERRORTYPES } from "../../config"
import { getEnvConfig } from "../../env"
import convertPDF2JPG from "../convertPDF/PDF2JPG"
import Alert from "../elements/Alert"
import Spinner from "../elements/Spinner"

interface ReceiptUploadProps {
	dispatch: Function
}
const ReceiptUpload: React.FC<ReceiptUploadProps> = ({ dispatch }) => {
	const env = getEnvConfig()

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<ERRORTYPES | null>()
	const [acceptedFile, setAcceptedFile] = useState<any>(null)

	const [receiptUploadRef] = useAutoAnimate<HTMLDivElement>()

	function onDrop(dropedFiles: any) {
		setLoading(true)
		if (dropedFiles.length > 0) {
			if (dropedFiles[0].size > 8000000) {
				// 8MB
				setError(ERRORTYPES.FILESIZE)
				setLoading(false)
				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({ event: "form-submit", "form-name": "receipt", "form-submit": false, field_error: "upload_receipt" })
				} catch (e) {}
			} else {
				setError(null)

				setAcceptedFile(dropedFiles[0]) // TODO: check if this needs to be holded in the state???
				handleFileType(dropedFiles[0])
				setLoading(true)
			}
		} else {
			setError(ERRORTYPES.FILESIZE)
			setLoading(false)
			try {
				// eslint-disable-next-line
				let dataLayer = window["dataLayer"] || []
				dataLayer.push({ event: "form-submit", "form-name": "receipt", "form-submit": false, field_error: "upload_receipt" })
			} catch (e) {}
		}
	}

	function handleFileTypeError(error: any) {
		try {
			if (error.response.data.errors.errors[0] === "Receipt already used.") {
				setError(ERRORTYPES.DOUBBLED)
			} else if (error.response.data.errors.errors[0] === "Reached maximum attempts with uploaded receipt.") {
				setError(ERRORTYPES.MAXTRIES)
			} else {
				setError(ERRORTYPES.RECEIPT_UPLOAD_ERROR)
			}
		} catch (error) {
			setError(ERRORTYPES.RECEIPT_UPLOAD_ERROR)
		}

		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "form-submit", "form-name": "receipt", "form-submit": false, field_error: "upload_receipt" })
		} catch (e) {}

		setLoading(false)
		setAcceptedFile(null)
	}

	function handleFileType(file) {
		setLoading(true)
		if (file.type !== "application/pdf") {
			uploadReceipt(file)
		} else {
			/* ✨ PDF magic ✨ */
			convertPDF2JPG(file)
				.then(file => {
					uploadReceipt(file)
				})
				.catch(error => {
					console.log("Error converting PDF to B64", error)
					setError(ERRORTYPES.RECEIPT_UPLOAD_ERROR)
					setLoading(false)
					try {
						// eslint-disable-next-line
						let dataLayer = window["dataLayer"] || []
						dataLayer.push({ event: "form-submit", "form-name": "receipt", "form-submit": false, field_error: "upload_receipt" })
					} catch (e) {}
				})
		}
	}

	async function uploadReceipt(file: any) {
		const env = getEnvConfig()
		const formdata = new FormData()
		formdata.append("file", file)

		axios
			.post(env.apibase + "uploadReceipt.php?cv=" + Date.now(), formdata, {
				headers: {
					"content-type": "multipart/form-data",
					Accept: "application/json",
				},
			})
			.then(res => {
				console.log(res)
				if (res.data.enqueued) {
					checkOCRStatus(res.data.temporaryReceiptDataId)

					try {
						// eslint-disable-next-line
						let dataLayer = window["dataLayer"] || []
						dataLayer.push({ event: "receipt", "receipt-upload": "upload-successful" })
					} catch (e) {}
				} else {
					handleFileTypeError(new Error("File submitted but something's wrong 🤔: " + JSON.stringify(res)))

					try {
						// eslint-disable-next-line
						let dataLayer = window["dataLayer"] || []
						dataLayer.push({ event: "form-submit", "form-name": "receipt", "form-submit": false, field_error: "upload_receipt" })
					} catch (e) {}
				}
			})
			.catch(error => {
				handleFileTypeError(error)
				setAcceptedFile(null)
			})
	}

	async function checkOCRStatus(reference: string, maxTries: number = 20) {
		axios
			.get(env.apibase + "checkReceiptUploadStatus.php?reference=" + reference + "&cv=" + Date.now())
			.then(res => {
				if (!!res.data.classificationPassed) {
					// Process succeeded

					if (res.data.classificationProcessed) {
						dispatch({
							type: "SET_RECEIPT_REFERENCE",
							receiptReference: reference,
							receiptFileName: reference,
						})
						try {
							// eslint-disable-next-line
							let dataLayer = window["dataLayer"] || []
							dataLayer.push({ event: "form-submit", "form-name": "receipt", "form-submit": true })
						} catch (e) {}
					} else {
						setError(ERRORTYPES.RECEIPT_UPLOAD_ERROR)
						setAcceptedFile(null)
					}

					setLoading(false)
				} else {
					maxTries--
					if (maxTries === 0) {
						setError(ERRORTYPES.RECEIPT_UPLOAD_ERROR)
						setLoading(false)

						console.log(new Error("Too many attempts!"))
						setAcceptedFile(null)

						try {
							// eslint-disable-next-line
							let dataLayer = window["dataLayer"] || []
							dataLayer.push({ event: "form-submit", "form-name": "receipt", "form-submit": false, field_error: "upload_receipt" })
						} catch (e) {}
					} else {
						setTimeout(() => checkOCRStatus(reference, maxTries), 1000)
					}
				}
			})
			.catch(error => {
				console.log(error)
				setError(ERRORTYPES.RECEIPT_UPLOAD_ERROR)
				setAcceptedFile(null)
				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({ event: "form-submit", "form-name": "receipt", "form-submit": false, field_error: "upload_receipt" })
				} catch (e) {}
			})
	}

	return (
		<Dropzone onDrop={onDrop} multiple={false} accept="image/jpeg,image/png,application/pdf">
			{({ getRootProps, getInputProps }) => (
				<section ref={receiptUploadRef}>
					{!loading ? (
						<div {...getRootProps()}>
							<input type="file" accept="image/jpeg,image/png,application/pdf" {...getInputProps()} />

							<div className="text-center md:min-h-[90px] max-w-[380px] mx-auto">
								{acceptedFile ? (
									<>
										<img src={process.env.PUBLIC_URL + "/images/icon-uploaded.svg"} width="74" height="71" alt="" className="block mx-auto  " />
										<p className="text-black text-[16px] max-w-[250px] mx-auto text-ellipsis  whitespace-nowrap overflow-hidden pb-4">{acceptedFile.name}</p>
									</>
								) : (
									<>
										<p className="text-ocker text-[15px] leading-[25px] pb-4 font-normal">
											Achte darauf, dass der Kaufbeleg vollständig ist und Datum, Einkaufsstätte, Aktionsprodukt sowie Preis gut lesbar sind.
										</p>
									</>
								)}
							</div>
							<button className="btn btn-small">Datei hochladen</button>
							<p className="text-ocker text-[15px] leading-[25px] pt-6 font-normal ">
								Möglich sind JPG-, PNG- oder PDF-Dateien <br className="hidden lg:inline-block" />
								bis max. 8 MB.
							</p>
						</div>
					) : (
						<div className="flex items-center justify-center text-center min-h-[160px]">
							<Spinner />
						</div>
					)}
					{error && <Alert className={"text-left mt-3"}>{error}</Alert>}
				</section>
			)}
		</Dropzone>
	)
}

export default ReceiptUpload
