import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"

const Terms: React.FC = () => {
	const navigate = useNavigate()

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<>
			<div className="pt-10 sm:pt-40">
				<div className="container max-w-[800px] mx-auto px-4  ">
					<h2 className="h2 pt-12 ">Teilnahme&shy;bedingungen </h2>
					<h3 className="h3 ">„Werther’s Original Caramel Bites gratis probieren“ 2024</h3>
					<h3 className="h3 py-4">1. Allgemeine Bedingungen</h3>
					<p className="mb-4 text-brown">
						Die August Storck KG, Waldstraße 27, 13403 Berlin (im Folgenden „Storck“) veranstaltet bis zum 31.05.2024 die Promotion „Werther’s Original Caramel Bites gratis
						probieren“ in Deutschland und Österreich.{" "}
					</p>
					<p className="mb-4 text-brown">
						Im Aktionszeitraum können Teilnehmer mit einer gekauften Aktionspackung der Marke Werther’s Original Caramel Bites, die mit dem Aktionshinweis „Jetzt gratis probieren“
						versehen ist, und dem Kassenbon ausschließlich online unter{" "}
						<a href="https://www.werthersoriginal-gratisprobieren.de" className="underline" target="_blank" rel="noreferrer">
							www.werthersoriginal-gratisprobieren.de
						</a>{" "}
						an der Geld-zurück-Aktion teilnehmen und erhalten den Kaufpreis auf das Konto erstattet. Nach dem 31.05.2024 ist eine Teilnahme nicht mehr möglich.
					</p>
					<p className="mb-4 text-brown">
						Pro Bankkonto kann während des Aktionszeitraums ein Beutel Werther’s Original Caramel Bites gratis probiert werden. Jeder Kassenbon und jede Aktionspackung darf nur
						einmalig zur Teilnahme an der Aktion genutzt werden.
					</p>
					<h3 className="h3 py-4">2. Teilnahme&shy;berechtigte</h3>
					<p className="mb-4 text-brown">
						Teilnehmen können nur natürliche Personen ab 18 Jahren, mit Konto im SEPA Raum (ein Land innerhalb der Europäischen Union sowie Island, Lichtenstein, Norwegen, Schweiz,
						Andorra, Monaco, San Marino, Vatikanstadt, Vereinigtes Königreich, Gibraltar, Isle of Man, Guernsey und Jersey). Der Betrag wird in Euro auf das vom Teilnehmer
						angegebene Konto überwiesen. Eventuelle Gebühren, die die kontoführende Bank des Teilnehmers für den Empfang des Geldes oder die Umrechnung von Euro in andere Währungen
						berechnet, hat der Kontoinhaber selbst zu tragen.
					</p>
					<p className="mb-4 text-brown">
						Eine Teilnahme ist nur höchstpersönlich möglich und erfordert die wahrheitsgemäße und vollständige Angabe der personenbezogenen Daten. Die Teilnahme über
						Gewinnspielvereine, automatisierte Dienste und vor allem über einen professionellen Gewinnspielservice ist nicht möglich.
					</p>
					<h3 className="h3 py-4">3. Ablauf</h3>
					<p className="mb-4 text-brown">
						Für die Teilnahme an der Promotion „Werther’s Original Caramel Bites gratis probieren“ ist der Kauf einer Werther’s Original Caramel Bites Aktionspackung mit
						Aktionshinweis erforderlich.
					</p>
					<p className="mb-4 text-brown">
						An der Aktion nehmen folgende Produkte teil: „Werther’s Original Caramel Bites Crunchy 140g“, „Werther’s Original Caramel Bites Cookie 140g“.
					</p>
					<p className="mb-4 text-brown">
						Um an der Aktion teilnehmen zu können, lädt der Teilnehmer auf der Aktionsseite{" "}
						<a href="https://www.werthersoriginal-gratisprobieren.de" className="underline" target="_blank" rel="noreferrer">
							www.werthersoriginal-gratisprobieren.de
						</a>{" "}
						zwei Fotos hoch, auf dem einmal der Kassenbon und einmal die dazugehörige Aktionspackung zu sehen sein müssen. Der Aktionshinweis auf der Packung muss dabei sichtbar
						sein. Auf dem Kassenbon abgebildete personenbezogene Daten (z. B. Namen von Beschäftigten) sind nicht abzulichten oder zumindest vorab zu schwärzen oder in sonstiger
						Weise unkenntlich zu machen. Auf dem Kassenbon müssen die folgenden Daten erkennbar sein: Datum, Uhrzeit, Einkaufsstätte sowie das Aktionsprodukt inkl. Preis und die
						Bonsumme. Die Bilder des Kassenbons und der Aktionspackung sind als JPG, PNG oder PDF hochzuladen und dürfen die maximale Dateigröße von 8 MB nicht überschreiten.
						Anschließend wählt der Teilnehmer aus, in welchem Land er das Produkt gekauft hat, wählt das gekaufte Aktionsprodukt aus, trägt den Kaufpreis des Produktes, das
						Kaufdatum und die Einkaufsstätte ein und ergänzt seine Kontaktdaten (E-Mail-Adresse, Vor- und Nachname), sowie die Kontoinformationen (Kontoinhaber, IBAN, bei
						ausländischen Konten zudem die BIC) in den jeweiligen Feldern auf{" "}
						<a href="https://www.werthersoriginal-gratisprobieren.de" className="underline" target="_blank" rel="noreferrer">
							www.werthersoriginal-gratisprobieren.de
						</a>
						. Nach dem Absenden der Teilnahmedaten erhält der Teilnehmer eine Bestätigungsmail. Storck prüft im Anschluss die Einhaltung der Teilnahmevoraussetzungen. Falls diese
						nicht vorliegen, erhält der Teilnehmer eine Benachrichtigung. In diesem Fall kann der Teilnehmer innerhalb des Aktionszeitraumes mit einem neuen Kassenbon erneut an der
						Aktion teilnehmen.
					</p>
					<p className="mb-4 text-brown">
						Für die vollständige und korrekte Angabe der Personen- und Bankdaten ist der Teilnehmer verantwortlich. Die Rückerstattung kann nur dann vorgenommen werden, wenn die
						angegebenen Daten korrekt sind. Die Rückerstattung des auf dem Kassenbon angegebenen Kaufpreises erfolgt innerhalb von 6 Wochen nach erfolgreicher Prüfung der
						Teilnahmevoraussetzungen. Eine Bestätigung der Überweisung per E-Mail wird nicht versendet.
					</p>
					<p className="mb-4 text-brown">Jeder Kassenbon und jede Aktionspackung darf nur einmalig zur Teilnahme an der Aktion genutzt werden.</p>
					<p className="mb-4 text-brown">Auszahlungsansprüche sind nicht auf Dritte übertragbar. </p>
					<h3 className="h3 py-4">4. Datenschutz</h3>
					<p className="mb-4 text-brown">
						Storck nutzt die im Rahmen der Promotion verarbeiteten personenbezogenen Daten ausschließlich zum Zwecke der Durchführung und Abwicklung der Promotion. Im Rahmen der
						Promotion arbeitet Storck mit seiner Tochtergesellschaft Storck Deutschland KG, Waldstraße 27, 13403 Berlin, sowie dem sorgfältig ausgewählten Dienstleister direct
						services Gütersloh GmbH, Reinhard-Mohn-Straße 300, 33333 Gütersloh zusammen. Die für Storck tätigen Auftragsverarbeiter sind ordnungsgemäß auf die Einhaltung des
						Datenschutzes verpflichtet. Für die Zahlungsabwicklung werden die Zahlungsdaten des Teilnehmers an das beauftragte Kreditinstitut übermittelt. Eine weitergehende
						Nutzung durch Storck oder Dritte findet nicht statt.{" "}
					</p>
					<p className="mb-4 text-brown">
						Im Übrigen gelten die Datenschutzbestimmungen unter{" "}
						<a
							href="https://www.werthers-original.de/de/datenschutz"
							className="underline"
							rel="noreferrer"
							target={"_blank"}
							onClick={() => {
								try {
									// eslint-disable-next-line
									let dataLayer = window["dataLayer"] || []
									dataLayer.push({ event: "terms", link: "datenschutz" })
								} catch (e) {}
							}}
						>
							https://www.werthers-original.de/de/datenschutz
						</a>
						.{" "}
					</p>
					<h3 className="h3 py-4">5. Sonstiges</h3>
					<p className="mb-4 text-brown">
						Sollten einzelne Bestimmungen dieser Teilnahmebedingungen ungültig sein oder werden, so bleibt die Gültigkeit der übrigen Bestandteile dieser Teilnahmebedingungen
						hiervon unberührt.{" "}
					</p>
					<p className="mb-4 text-brown">Es gilt das Recht der Bundesrepublik Deutschland. Der Rechtsweg ist ausgeschlossen.</p>
				</div>
			</div>
			<div className="text-center pt-5 py-10">
				<button
					onClick={() => {
						navigate(-1)
						try {
							// eslint-disable-next-line
							let dataLayer = window["dataLayer"] || []
							dataLayer.push({ event: "terms", link: "zurück" })
						} catch (e) {}
					}}
					className="btn btn-default mt-8"
				>
					Zurück
				</button>
			</div>
		</>
	)
}
export default Terms
