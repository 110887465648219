import { useContext } from "react"

import { PACKAGES } from "../../config"
import { Context } from "../../store/store"

interface SelectPackageProps {
	formElementChanged: Function
}
const SelectPackage: React.FC<SelectPackageProps> = ({ formElementChanged }) => {
	const { globalState } = useContext(Context)
	return (
		<div className="pb-10">
			<div className="sm:px-12">
				<div className="text-white text-center  text-[21px] sm:text-[23px]  pb-10">Wähle hier deine gekaufte Aktionspackung:</div>
				<div className="grid grid-cols-2 gap-1 sm:gap-6 max-w-2xl sm:px-4 mx-auto">
					{Object.keys(PACKAGES).map(item => {
						const isSelected = item === globalState.package ? true : false
						return (
							<div key={"package" + PACKAGES[item]} onClick={() => formElementChanged({ target: { value: item } }, "package")} className=" cursor-pointer sm:px-6">
								<img src={process.env.PUBLIC_URL + "/images/packs/" + item + ".png"} alt={item} loading="lazy" className="w-auto  max-h-[230px] mx-auto pb-5" />
								<div className="relative pl-6">
									{isSelected ? (
										<img
											src={process.env.PUBLIC_URL + "/images/icon-checkbox-checked.svg"}
											key="not selectted"
											alt={"selected"}
											loading="lazy"
											className="mx-auto w-[20px] absolute left-0 top-0"
										/>
									) : (
										<img
											src={process.env.PUBLIC_URL + "/images/icon-checkbox.svg"}
											key="selectted"
											alt={"not selected"}
											loading="lazy"
											className="mx-auto w-[20px] absolute left-0 top-0 "
										/>
									)}
									<div className="font-Poppins text-[18px] sm:text-[19px]  leading-tight  text-white pl-2">{PACKAGES[item]}</div>
								</div>
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default SelectPackage
