import { useContext } from "react"
import { Context } from "../../store/store"
import RadioSurvey from "../elements/RadioSurvey"

interface SurveyProps {
	formElementChanged: Function
	validation: Object
}
const Survey: React.FC<SurveyProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)
	return (
		<section className="container">
			<h2 className="h2 text-center sm:pt-6 pb-10">Bevor du absendest</h2>

			<div className="bg-[#FBD79F] -mx-4">
				<ul className="max-w-[732px] mx-auto pt-10 pb-8 text-brown font px-4">
					<li className="grid grid-cols-2 sm:grid-cols-[1fr_80px_80px] sm:gap-20">
						<div className="font-bold col-span-2 sm:col-span-1">Hast du das Produkt zum ersten Mal probiert?</div>
						<RadioSurvey
							key={"survey1"}
							label={"Ja"}
							props={{
								name: "quest1",
								value: "a",
								type: "radio",
								onChange: e => formElementChanged(e, "question1"),
								checked: "a" === globalState.question1,
							}}
						></RadioSurvey>
						<RadioSurvey
							key={"survey2"}
							label={"Nein"}
							props={{
								name: "quest1",
								value: "b",
								type: "radio",
								onChange: e => formElementChanged(e, "question1"),
								checked: "b" === globalState.question1,
							}}
						></RadioSurvey>
					</li>
					<li className="grid grid-cols-2 sm:grid-cols-[1fr_80px_80px] sm:gap-20 mt-6 sm:mt-0">
						<div className="font-bold col-span-2 sm:col-span-1">Würdest du das Produkt weiterempfehlen?</div>
						<RadioSurvey
							key={"survey3"}
							label={"Ja"}
							props={{
								name: "quest1",
								value: "a",
								type: "radio",
								onChange: e => formElementChanged(e, "question2"),
								checked: "a" === globalState.question2,
							}}
						></RadioSurvey>
						<RadioSurvey
							key={"survey4"}
							label={"Nein"}
							props={{
								name: "quest1",
								value: "b",
								type: "radio",
								onChange: e => formElementChanged(e, "question2"),
								checked: "b" === globalState.question2,
							}}
						></RadioSurvey>
					</li>
				</ul>
			</div>
		</section>
	)
}

export default Survey
