import { useContext } from "react"
import { Context } from "../../store/store"
import ElemRadio from "../elements/Radio"
import Alert from "../elements/Alert"

interface SelectCountryProps {
	formElementChanged: Function
	validation: Object
}
const SelectCountry: React.FC<SelectCountryProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)
	const options = [
		{ value: "de", label: "Deutschland" },
		{ value: "at", label: "Österreich" },
	]
	return (
		<>
			<div className="text-white text-center  text-[21px] sm:text-[23px] pb-10">In welchem Land hast du dein Produkt gekauft?*</div>
			<div className="grid grid-cols-1 md:grid-cols-2 gap-4 max-w-lg mx-auto px-4 pb-8">
				{options.map((option, optIndex) => {
					return (
						<ElemRadio
							key={"formRadCountry" + optIndex}
							label={option.label}
							props={{
								name: "country",
								value: option.value,
								type: "radio",
								onChange: e => formElementChanged(e, "country"),
								checked: option.value === globalState.country,
							}}
							country={option.value}
						></ElemRadio>
					)
				})}
				{validation["invalid_country"] && (
					<>
						<Alert>Bitte Einkaufsland auswählen</Alert>
					</>
				)}
			</div>
		</>
	)
}

export default SelectCountry
