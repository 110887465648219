import { useContext } from "react"
import { Context } from "../../store/store"
import ElemCheckbox from "../elements/Checkbox"

interface LegalProps {
	formElementChanged: Function
	validation: Object
}
const Legal: React.FC<LegalProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)
	return (
		<div className="pt-4 sm:pt-12  max-w-[380px] mx-auto">
			<ElemCheckbox
				label="Ich bin mindestens 18 Jahre alt.*"
				props={{
					type: "checkbox",
					name: "age",
					required: "required",
					value: globalState.age,
					onClick: e => formElementChanged(e, "age"),
				}}
				hasError={validation["invalid_age"] ? true : false}
				className="sm:pb-10"
				errMsg={validation["invalid_age_errMsg"] || "Bitte Alter bestätigen."}
			/>
		</div>
	)
}

export default Legal
