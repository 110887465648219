interface RadioProps {
	props?: any
	className?: string | null
	label?: any
	country?: string
}

const ElemRadio: React.FC<RadioProps> = ({ className, props, label, country }) => {
	return (
		<label className={"relative block cursor-pointer mb-4" + (className || "")}>
			<input className={"w-2 h-2 hidden absolute"} {...props} type="radio" value={props.value} />
			{props.checked ? (
				<img src={process.env.PUBLIC_URL + "/images/icon-checkbox-checked.svg"} alt={"selected"} loading="lazy" className="peer-checked:block top-[2px] absolute w-[20px]" />
			) : (
				<img src={process.env.PUBLIC_URL + "/images/icon-radio.svg"} alt={"not selected"} loading="lazy" className="peer-checked:hidden block top-[2px] absolute w-[20px]" />
			)}
			<div className=" pl-8  text-[18px] sm:text-[19px] pt-0  text-white ">
				{label}
				{country && <img src={process.env.PUBLIC_URL + "/images/" + country + ".png"} alt={"not selected"} loading="lazy" className="inline-block pl-4" />}
			</div>
		</label>
	)
}

export default ElemRadio
