import { useAutoAnimate } from "@formkit/auto-animate/react"
import { useState } from "react"
import Dropzone from "react-dropzone"
import { ERRORTYPES } from "../../config"
import convertPDF2JPG from "../convertPDF/PDF2JPG"
import Alert from "../elements/Alert"
import Spinner from "../elements/Spinner"

interface ProductFotoUploadProp {
	dispatch: Function
}
const ProductFotoUpload: React.FC<ProductFotoUploadProp> = ({ dispatch }) => {
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<ERRORTYPES | null>()
	const [acceptedFile, setAcceptedFile] = useState<any>(null)

	const [receiptUploadRef] = useAutoAnimate<HTMLDivElement>()

	function onDrop(dropedFiles) {
		setLoading(true)
		if (dropedFiles.length > 0) {
			if (dropedFiles[0].size > 8000000) {
				// 8MB
				setError(ERRORTYPES.FILESIZE)
				setLoading(false)
				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({ event: "form-submit", "form-name": "photo", "form-submit": false, field_error: "upload_photo" })
				} catch (e) {}
			} else {
				setError(null)
				setLoading(true)
				handleFileType(dropedFiles[0])
			}
		} else {
			setError(ERRORTYPES.FILESIZE)
			setLoading(false)
			try {
				// eslint-disable-next-line
				let dataLayer = window["dataLayer"] || []
				dataLayer.push({ event: "form-submit", "form-name": "photo", "form-submit": false, field_error: "upload_photo" })
			} catch (e) {}
		}
	}

	function saveFile(file) {
		dispatch({
			type: "SET_PRODUCT_IMG",
			payload: file,
		})
		setAcceptedFile({ name: file.name })
		setLoading(false)

		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "form-submit", "form-name": "photo", "form-submit": true })
		} catch (e) {}
	}

	function handleFileType(file) {
		if (file.type !== "application/pdf") {
			saveFile(file)
		} else {
			/* ✨ PDF to IMG magic ✨ */
			convertPDF2JPG(file)
				.then(convertedFile => {
					saveFile(convertedFile)
					setAcceptedFile({ name: file.name }) // overwrite for filename, otherwise filename ist lost
				})
				.catch(error => {
					try {
						// eslint-disable-next-line
						let dataLayer = window["dataLayer"] || []
						dataLayer.push({ event: "form-submit", "form-name": "photo", "form-submit": false, field_error: "upload_photo" })
					} catch (e) {}
					console.log("Error converting PDF to B64", error)
					setError(ERRORTYPES.RECEIPT_UPLOAD_ERROR)
					setLoading(false)
				})
		}
	}

	return (
		<Dropzone onDrop={onDrop} multiple={false} accept="image/jpeg,image/png,application/pdf">
			{({ getRootProps, getInputProps }) => (
				<section ref={receiptUploadRef}>
					{!loading ? (
						<div {...getRootProps()}>
							<input type="file" accept="image/jpeg,image/png,application/pdf" {...getInputProps()} />

							<div className="text-center md:min-h-[90px] max-w-[380px] mx-auto">
								{acceptedFile && (
									<>
										<img src={process.env.PUBLIC_URL + "/images/icon-uploaded.svg"} width="74" height="71" alt="" className="block mx-auto " />
										<p className="text-black text-[16px] max-w-[250px] mx-auto text-ellipsis  whitespace-nowrap overflow-hidden pb-4">{acceptedFile.name}</p>
									</>
								)}
							</div>
							<button className="btn btn-small">Datei hochladen</button>
							<p className="text-ocker text-[15px] leading-[25px] font-normal  pt-6 ">
								Möglich sind JPG-, PNG- oder PDF-Dateien <br className="hidden lg:inline-block" />
								bis max. 8 MB.
							</p>
						</div>
					) : (
						<div className="flex items-center justify-center text-center min-h-[160px]">
							<Spinner />
						</div>
					)}
					{error && <Alert className={"text-left mt-3"}>{error}</Alert>}
				</section>
			)}
		</Dropzone>
	)
}

export default ProductFotoUpload
