import { useContext } from "react"
import { COUNTRY, MAX_PRODUCT_PRICE } from "../../config"
import { Context } from "../../store/store"
import ElemInput from "../elements/Input"
import ElemSelect from "../elements/Select"
import SelectPackage from "./SelectPackage"
import SelectCountry from "./SelectCountry"

interface ProductsProps {
	formElementChanged: Function
	validation: Object
}
const Product: React.FC<ProductsProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)
	return (
		<section className="container ">
			<div className="text-center">
				<h2 className="h2 pb-11">Angaben zum Produkt</h2>
			</div>
			<div className="bg-red pt-16 pb-8 -mx-4 px-4">
				<SelectCountry {...{ formElementChanged, validation }} />

				<SelectPackage {...{ formElementChanged }} />

				<div className="grid gap-5 grid-cols-1 md:grid-cols-3 items-start mb-6 max-w-[750px] mx-auto">
					<ElemInput
						label={"Kaufbetrag*"}
						props={{
							type: "number",
							step: "0.01",
							min: "0.1",
							max: MAX_PRODUCT_PRICE,
							name: "purchaseAmount",
							value: globalState.purchaseAmount,
							required: "required",
							onChange: e => formElementChanged(e, "purchaseAmount"),
						}}
						hasError={validation["invalid_purchaseAmount"] ? true : false}
						className="col-span-2 md:col-span-1"
						suffix="EUR"
						errMsg={validation["invalid_purchaseAmount_errMsg"] || "Bitte Kaufbetrag eingeben. (max. 5,00 EUR)"}
					/>

					<ElemInput
						label={"Kaufdatum*"}
						props={{
							type: "date",
							name: "purchaseDate",
							value: globalState.purchaseDate,
							required: "required",
							placeholder: "TT.MM.YYYY",
							minLength: 1,
							min: "2023-12-15",
							onChange: e => formElementChanged(e, "purchaseDate"),
						}}
						// TODO: Datepicker
						hasError={validation["invalid_purchaseDate"] ? true : false}
						className="col-span-2 md:col-span-1"
						errMsg={validation["invalid_purchaseDate_errMsg"] || "Bitte gültiges Kaufdatum eingeben (15.12.2023 - 31.05.2024)"}
					/>

					<ElemSelect
						label={"Einkaufsstätte*"}
						props={{
							name: "merchant",
							value: globalState.merchant,
							onChange: e => formElementChanged(e, "merchant"),
							required: "required",
						}}
						hasError={validation["invalid_merchant"] ? true : false}
						className="col-span-2 md:col-span-1"
						errMsg={validation["invalid_merchant_errMsg"] || "Bitte Einkaufsstätte angeben"}
					>
						{globalState.country === COUNTRY.DE && (
							<>
								<option value="">bitte wählen</option>
								<option value="a">Kaufland</option>
								<option value="b">Rewe</option>
								<option value="c">Edeka</option>
								<option value="d">Netto</option>
								<option value="e">Aldi</option>
								<option value="f">Penny</option>
								<option value="g">Norma</option>
								<option value="h">Globus</option>
								<option value="i">Storck Online-Shop</option>
								<option value="k">Andere</option>
							</>
						)}
						{globalState.country === COUNTRY.AT && (
							<>
								<option value="">bitte wählen</option>
								<option value="a">Billa / Billa Plus</option>
								<option value="b">Spar / Eurospar / Interspar</option>
								<option value="c">Penny</option>
								<option value="d">Unimarkt</option>
								<option value="e">Mpreis</option>
								<option value="f">Andere</option>
							</>
						)}
					</ElemSelect>
				</div>
			</div>
		</section>
	)
}

export default Product
