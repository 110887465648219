import { useEffect } from "react"
import Hero from "../components/home/Hero"
import { Link } from "react-router-dom"

function Promoover() {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<>
			<Hero />

			<div className="text-center font-Poppins text-[37px] sm:text-[51px] leading-[43px] sm:leading-[58px] text-red font-extrabold px-4 pt-6 sm:pt-12 mb-8">
				DIESE AKTION IST LEIDER BEENDET!
			</div>

			<div className="text-ocker text-[22px] font-Poppins text-center font-bold mt-9 mb-8">Unsere Gratis-probieren-Aktion ist seit dem 31.05.2024 beendet.</div>

			<div className=" text-center px-4 mx-auto text-brown py-4 text-[14px] sm:text-[15px] leading-normal max-w-4xl">
				Du hast noch Fragen zur Aktion? Kein Problem!
				<br />
				<br />
				Kontaktiere uns einfach per E-Mail unter{" "}
				<a
					href="mailto:info@werthersoriginal-gratisprobieren.de"
					onClick={() => {
						try {
							// eslint-disable-next-line
							let dataLayer = window["dataLayer"] || []
							dataLayer.push({ event: "contact", link: "mail" })
						} catch (e) {}
					}}
				>
					info@werthersoriginal-gratisprobieren.de
				</a>
				.<br />
				<br />
				Besuche uns gerne auf <a href="https://www.werthers-original.de/">werthers-original.de</a>, um nichts zu verpassen.
			</div>

			<div className="pt-16 pb-20 text-center ">
				<a href={"https://www.werthers-original.de/"} className="btn btn-default  " target="_blank">
					Zu Werther‘s original
				</a>
			</div>
		</>
	)
}
export default Promoover
